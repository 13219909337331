@import "../stylesheets/variables";

.app-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .fill-container {
        flex: 1;
        min-height: 0;
        overflow: auto;
    }
}

.default-appbar {
    background-color: $primary;
    display: flex;
    align-items: center;
    height: $arnold-appbar-height;
}

.default-layout {
  position: relative;
  max-height: calc(100vh - $arnold-appbar-height);
  overflow: auto;
}
