@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.brand {
  width: 75px !important;
  height: 75px;

  padding: 0.75rem;

  z-index: 100;
  color: $white;
  box-shadow: 0 0 1rem 0.25rem rgba(0, 0, 0, 0.1);
}

.login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $white;
  padding: 3rem 1rem 1rem 1rem;

  min-width: 300px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.login__header {
  display: flex;
  justify-content: center;
}

.login__icon {
  margin-top: -80px;
  margin-bottom: 30px;
}

.login__content {
  padding: 0 1rem 1rem 1rem;
}
